<template>
  <div class="user-login" v-if="!autoLogin">
    <!-- 背景层 -->
    <div class="ul--background"></div>
    <!-- header -->
    <div class="ul--header">
      <!-- <div class="h--logo" v-if="!notShowLogo"></div> -->
      <!-- 语言切换 -->
      <div class="h--languages">
        <el-select
          v-model="$i18n.locale"
          popper-class="user-login--language-select"
          @change="changeLan"
        >
          <el-option value="zh-cn" label="简体中文"></el-option>
          <el-option value="en" label="English"></el-option>
        </el-select>
      </div>
    </div>
    <!-- 登陆表单容器 -->
    <div class="ul--body">
      <div class="b--title">
        <p>{{ notShowLogo ? lang.jiuYuanTitle : lang.platformName }}</p>
      </div>
      <div class="ul--form">
        <!-- bg -->
        <div class="f--blur-bg">
          <div class="b--image"></div>
        </div>
        <!-- content -->
        <div class="f--content">
          <!-- header -->
          <div class="f--header">
            <span>{{ lang.userLogin }}</span>
          </div>
          <!-- body form -->
          <div class="f--body">
            <els-form
              ref="elsFormRef"
              :column="formColumn"
              :elForm="elForm"
              @submit="(d) => (userInfo = d)"
            ></els-form>
          </div>
          <!-- footer button -->
          <div class="f--footer">
            <els-button-group
              type="primary"
              :column="buttons"
              @click="handleClick"
            ></els-button-group>
          </div>

          <!-- regist 找回密码 -->
          <div class="f--links">
            <els-button-group
              type="text"
              :column="links"
              delimiter="divider"
            ></els-button-group>
          </div>
        </div>
      </div>
    </div>

    <!-- 底栏信息 -->
    <div class="website-info">
      <span>{{ lang.hanYun.copyright }}</span>
    </div>
  </div>
</template>

<script>
import elsForm from "@/components/els-form/main.vue";
import { login, getMenu, getUserInfo, getVerifyCode } from "@/api/base/login";
import { getButtons, getMenus } from "@/utils/menuTree";
import { HomePath, buildMenus } from "@/config/menus.js";
import { parseToken, isAdmin } from "@/utils/util.js";
import mixinsI18n from "@/mixins/i18n.js";
import { saveLocal } from "@/utils/util.js";
export default {
  mixins: [mixinsI18n],
  components: { elsForm },
  data: () => ({
    PAGE_NAME: "loginPage",
    loading: false,
    userInfo: {},
    codeImg: "",
    language: "zh",
    //
    autoLogin: false,
  }),
  computed: {
    elForm() {
      return { inline: false, size: "large" };
    },
    formColumn() {
      // const col0 = {tag:'el-form-item',label:'',prop:'',required:true,cls:[
      //   {tag:'el-input',prefixIcon:'el-icon-key',required:true,size:'large',placeholder:'验证码',prop:'code'},
      // ]}
      const col1 = {
        tag: "el-form-item",
        message: this.lang.uMess,
        prop: "username",
        required: true,
        cls: [
          {
            tag: "el-input",
            prefixIcon: "el-icon-user",
            size: "large",
            placeholder: this.lang.userName,
          },
        ],
      };
      const col2 = {
        tag: "el-form-item",
        message: this.lang.pMess,
        prop: "password",
        required: true,
        cls: [
          {
            tag: "el-input",
            prefixIcon: "el-icon-lock",
            type: "password",
            size: "large",
            placeholder: this.lang.password,
          },
        ],
      };
      //
      const col3 = {
        tag: "div",
        class: "f--verify-code",
        cls: [
          {
            tag: "el-form-item",
            message: "请输入 [ 验证码 ]",
            prop: "code",
            required: true,
            cls: [
              {
                tag: "el-input",
                prefixIcon: "el-icon-key",
                size: "large",
                placeholder: "验证码",
              },
            ],
          },
          {
            tag: "img",
            width: "150px",
            height: "48px",
            hook: {
              created() {
                this.handleClick = async () => {
                  try {
                    const res = await getVerifyCode();
                    this.value = res.result;
                  } catch (e) {
                    console.log(e);
                  }
                };
                //
                this.elem.on = { click: this.handleClick };
                //
                this.handleClick();
              },
              context(ctx) {
                ctx.domProps.src = this.value;
              },
            },
          },
        ],
      };

      const column = [col1, col2];
      const elForm = { inline: false, size: "large" };
      console.log(column, JSON.stringify(column));
      // return { column, elForm };
      return [col1, col2];
    },
    buttons() {
      const d = {
        label: this.lang.login,
        // icon: "el-icon-s-promotion",
        loading: this.loading,
      };
      return [d];
    },
    links() {
      const password = { label: this.lang.forgetPass };
      const register = { label: this.lang.reAcc };
      return [password, register];
    },
    notShowLogo() {
      return process.env.VUE_APP_NOT_SHOW_LOGO;
    },
  },
  methods: {
    async handleClick() {
      // this.loading = true;
      // console.log(this.userInfo);
      try {
        await this.$refs.elsFormRef.validate();
        //
        this.loading = true;
        await this.login(this.userInfo);
        //
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async login(d = {}) {
      try {
        const { token } = await login(d);
        //
        const tokenObj = parseToken(token);
        const bool = isAdmin(tokenObj);
        // 判断是否是租户管理员
        if (bool) {
          const url = `${window.$location.protocol}//${window.$location.hostname}:8090/login?token=${token}`;
          window.open(url, "_self");
          return;
        }
        //
        await this.resetUserInfo(token);
        const ln = this.$i18n.locale;
        const path = `/${ln}${HomePath}`;
        this.$router.push(path);
        return Promise.resolve();
      } catch (e) {
        console.log(e);
        return Promise.reject();
      }
    },
    async resetUserInfo(token) {
      try {
        // await this.$store.dispatch("noLogin", token);
        this.$store.commit("setToken", token);
        this.$store.dispatch("getUserInfo", token);
        // this.$store.commit("setToken", token);
        // // 获取用户数据
        // // 获取菜单路由数据
        // const [{ result: tree }, userInfo] = await Promise.all([
        //   getMenu(),
        //   getUserInfo(token),
        // ]);
        // // 存入 userinfo
        // this.$store.commit("setUserInfo", userInfo);
        // // 解析数据 并存入 store 中
        // const buttons = getButtons(tree);
        // const sourceMenus = getMenus(tree);
        // const menus = buildMenus(sourceMenus);
        // this.$store.commit("setButtons", buttons);
        // this.$store.commit("setSourceMenus", sourceMenus);
        // //
        // this.$store.commit("setMenus", menus);
        // // 重建路由 并导航到初始路由页面
        // this.$router.$rebuild();
        // //
        // // console.log(this.$router.getRoutes(), this.$route.path);
        // if (this.$route.path.includes("/login") || this.$route.path == "/") {
        //   const ln = this.$i18n.locale;
        //   const path = `/${ln}${HomePath}`;
        //   // console.log(ln, path);
        //   this.$router.push(path);
        // }
        //
        return Promise.resolve();
      } catch (e) {
        console.log(e);
      }
      return Promise.reject();
    },
    redirect() {
      let uri = this.$route.query.redirect;
      if (!uri) {
        const ln = this.$i18n.locale;
        uri = `/${ln}${HomePath}`;
      }
      this.$router.push(uri);
    },
    changeLan() {
      saveLocal("i18nLocal", this.$i18n.locale);
      window.document.title = this.notShowLogo
        ? this.$t("d.jiuYuanTitle")
        : this.$t("d.webTitle");
      this.$router.push("/" + this.$i18n.locale + "/login");
    },
  },
  created() {
    // this.login()
    // console.log(this.$route)
    //
    this.autoLogin = !!(this.$route?.query?.name || this.$route?.query?.token);
    if (this.$route?.query?.platformToken) {
      this.autoLogin = true;
      this.$store.commit("setPlatformToken", this.$route.query.platformToken);
    }
    if (this.$route?.query?.sunnyToken) {
      window.__POWERED_BY_WUJIE__ = true;
      this.autoLogin = true;
      this.$store.dispatch("noLogin", this.$route?.query?.sunnyToken);
    }

    if (this.autoLogin) {
      if (this.$route?.query?.name) {
        this.login({
          username: this.$route.query.name,
          password: "123456",
        }).catch(() => {
          this.autoLogin = false;
        });
      }

      if (this.$route?.query?.token) {
        this.resetUserInfo(this.$route.query.token).catch(() => {
          this.autoLogin = false;
        });
      }
    }
    //
    if (this.autoLogin && this.$store.getters.token) {
      this.redirect();
    }
  },
};
</script>

<style scoped lang="scss">
.user-login {
  width: 100vw;
  height: 100vh;

  .ul--background {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/login/back.png") no-repeat;
    background-size: 100% 100%;
    left: 0;
    top: 0;
  }
  .ul--header {
    position: relative;
    height: 88px;
    width: 100%;
    background: linear-gradient(
      90deg,
      #0d1e3c 0%,
      rgba(11, 25, 49, 0.5) 37%,
      rgba(2, 19, 36, 0) 100%
    );
    .h--logo {
      position: absolute;
      left: 48px;
      top: 24px;
      width: 182px;
      height: 45px;
      background: url("../../assets/images/login/logo.png") no-repeat;
      background-size: 100% 100%;
    }
    .h--languages {
      position: absolute;
      right: 48px;
      top: 24px;
      width: 140px;
      height: 40px;
      ::v-deep .el-input {
        input {
          background: #142954;
          border: none;
          color: #ffffff;
          font-size: 16px;
        }
      }
    }
  }
  .ul--body {
    position: absolute;
    width: 465px;
    height: 530px;
    left: 50%;
    top: calc(50%);
    transform: translateX(-50%) translateY(-50%);
    .b--title {
      width: 100%;
      height: 97px;
      display: flex;
      align-items: center;
      justify-content: center;
      > p {
        font-size: 40px;
        font-weight: 600;
        color: #ffffff;
        background: linear-gradient(180deg, #ffffff 0%, #b4daff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        white-space: nowrap;
      }
    }
    .ul--form {
      position: relative;
      height: calc(100% - 97px);
      background: rgba(44, 73, 152, 0.3);
      box-shadow: 0px 0px 10px 0px rgba(2, 0, 77, 0.5);
      .f--blur-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        filter: blur(10px);
        z-index: -1;
        .b--image {
          position: fixed;
          left: 232px;
          top: 168px;
          transform: translate(-50%, -50%);
          width: 100vw;
          height: 100vh;
          background: url("../../assets/images/login/back.png") no-repeat;
          background-size: 100vw 100vh;
        }
      }
      .f--content {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding: 48px;
        .f--header {
          height: 40px;
          display: flex;
          align-items: center;
          > span {
            font-size: 20px;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .f--body {
          margin-top: 24px;
          ::v-deep .el-form-item {
            height: 40px;
            margin-bottom: 24px;
            .el-input {
              font-size: 16px;
              > input {
                color: #253f86;
              }
              .el-input__prefix {
                color: skyblue;
              }
            }
          }
        }
        .f--footer {
          margin-top: 48px;
          height: 40px;
          ::v-deep .el-button {
            width: 100%;
            border-radius: 0;
            font-size: 16px;
          }
        }
        .f--links {
          height: 40px;
          margin-top: 40px;
          .els-button-group {
            display: flex;
            justify-content: center;
            align-items: center;

            ::v-deep .el-divider {
              background-color: #1890ff;
            }
          }
        }
      }
    }
  }
}
.website-info {
  position: absolute;
  width: 100%;
  bottom: 24px;
  text-align: center;
  white-space: pre;
  > span {
    display: block;
    color: #aaa;
    font-size: 12px;
    > a {
      color: #1f73e0;
    }
  }
}
</style>

<style lang="scss">
.user-login--language-select {
  border-color: #142954;
  .el-scrollbar {
    background: #142954;
    .el-select-dropdown__item {
      background: #142954;
      color: #fff;
      &.selected {
        background: #1890ff;
      }
      &:not(.selected):hover {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
  .popper__arrow {
    border-bottom-color: #142954 !important;
    &::after {
      border-bottom-color: #142954 !important;
    }
  }
}
</style>
